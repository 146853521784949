
import Vue from "vue";
import router from '@/router'
export default Vue.extend({
  name: "users-list",
  components: {},
  data() {
    return {
      loading: false,
      msg: "",
      recent: [
        {
          active: true,
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Jason Oner',
        },
        {
          active: true,
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Mike Carlson',
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Cindy Baker',
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          title: 'Ali Connors',
        },
      ],
      previous: [{
        title: 'Travis Howard',
        avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
      }],
    };
  },
  methods: {
    edit() {
      const currentPath = router.currentRoute.path;
      const path = currentPath.replace("view", "edit");
      router.push(path);
    },
    send() {},
  },
  mounted() {
    const elem = document.querySelector(".chat-msgs")!;
    elem.scrollTop = elem.scrollHeight;
    //   this.$refs.msgs?.scrollTop = this.$refs.msgs!.scrollHeight
    //   console.log(this.$refs.msgs!)
  },
});
